import * as React from "react";

import { MediumColumnWidthWrapper } from "../confines-wrappers";
import styled, { css } from "../../theme/styled";
import { HeadingUppercase } from "../heading";
import {
  fontSizeHelper,
  fontWeightHelper,
  colourHelper
} from "../../theme/theme-helper";
import Link from "../link";

/**
 * How?
 */
const How: React.FC = () => (
  <HowStyled className="how">
    <MediumColumnWidthWrapper className="content-container">
      <div className="how-wrapper">
        <HeadingUppercase
          as="h2"
          color="brandColor"
        >
          How?
        </HeadingUppercase>

        <div className="in-house-outsource">
          <div className="in-house">
            <h3>
              In-house
            </h3>

            <p>
              Our team integrates with yours to offer expertise and a hands-on
              approach in facilitating internal innovation.
            </p>
          </div>

          <div className="outsource">
            <h3>
              Outsource
            </h3>

            <p>
              Our team focusses its attention solely on your project over a set
              period, producing quality results in a matter of days.
            </p>
          </div>

          <span className="vertical-line" />
        </div>

        <p className="in-between">
          &hellip;or somewhere in between, it’s up to you.
        </p>

        <p className="book">
          <span className="off-screen">
            Click here to
            {" "}
          </span>

          <Link
            type="styled"
            color="brandColor"
            href="/insights"
          >
            Book your hackathon
          </Link>
        </p>
      </div>
    </MediumColumnWidthWrapper>
  </HowStyled>
);

export default How;

const HowStyled = styled.div`
  ${({ theme }) => css`
    background-color: ${colourHelper({
    theme,
    variant: "altLightGrey"
  })};

    .how-wrapper {
      .in-house-outsource {
        margin-top: 4%;

        div {
          padding-top: 2.8rem;

          &:first-child {
            padding-top: 0;
          }

          h3 {
            font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_3"
  })};
          }

          p {
            padding-top: 1em;
            font-size: ${fontSizeHelper({
    theme,
    variant: "body_2"
  })};
          }
        }
      }

      .in-between {
        padding-top: 4.2rem;
        text-align: center;
        font-size: ${fontSizeHelper({
    theme,
    variant: "body_2"
  })};
        font-weight: ${fontWeightHelper({
    theme,
    variant: "bold"
  })};
      }

      .book {
        padding-top: 2.6rem;
        text-align: center;
        font-size: ${fontSizeHelper({
    theme,
    variant: "body_1"
  })};
        font-weight: ${fontWeightHelper({
    theme,
    variant: "bold"
  })};
      }
    }

    @media (min-width: 375px) {
      .how-wrapper {
        .in-house-outsource {
          div {
            h3 {
              font-size: ${fontSizeHelper({
    theme,
    variant: "title"
  })};
            }
          }
        }
      }
    }

    @media (min-width: 568px) {
      .how-wrapper {
        .in-house-outsource {
          display: flex;
          position: relative;
          padding-top: 1rem;
          padding-bottom: 1rem;

          div {
            padding-top: 0;
            width: 50%;

            &:nth-child(odd) {
              padding-right: 10%;
            }

            &:nth-child(even) {
              padding-left: 10%;
            }

            h3 {
              font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_3"
  })};
            }
          }

          .vertical-line {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0.2rem;
            height: 100%;
            background-color: ${colourHelper({
    theme,
    variant: "borderColor"
  })};
          }
        }

        .in-between {
          padding-top: 4.6rem;
        }
      }
    }

    @media (min-width: 600px) {
      .how-wrapper {
        .in-house-outsource {
          margin-top: 1.2rem;

          div {
            h3 {
              font-size: ${fontSizeHelper({
    theme,
    variant: "display_1"
  })};
            }
          }
        }

        .in-between {
          padding-top: 6.2rem;
        }
      }
    }

    @media (min-width: 768px) {
      .how-wrapper {
        .in-house-outsource {
          margin-top: 1.8rem;
        }

        .in-between {
          padding-top: 8.2rem;
        }
      }
    }

    @media (min-width: 960px) {
      .how-wrapper {
        .in-house-outsource {
          justify-content: space-between;

          div {
            padding-top: 0;
            padding-left: 0 !important;
            padding-right: 0 !important;
            width: 50%;
            max-width: 34rem;
          }
        }
      }
    }

    @media (min-width: 1024px) {
      .how-wrapper {
        .in-house-outsource {
          div {
            max-width: 40rem;

            h3 {
              font-size: ${fontSizeHelper({
    theme,
    variant: "display_2"
  })};
            }

            p {
              font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_1"
  })};
            }
          }
        }

        .in-between {
          padding-top: 10.2rem;
          font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_1"
  })};
        }

        .book {
          font-size: ${fontSizeHelper({
    theme,
    variant: "body_2"
  })};
        }
      }
    }

    @media (min-width: 1280px) {
      .how-wrapper {
        .in-house-outsource {
          div {
            max-width: 45rem;

            h3 {
              font-size: ${fontSizeHelper({
    theme,
    variant: "display_3"
  })};
            }

            p {
              font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_2"
  })};
            }
          }
        }

        .in-between {
          padding-top: 12.2rem;
          font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_2"
  })};
        }
      }
    }
  `}
`;
