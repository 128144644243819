import * as React from "react";

import TestimonialCarousel from "../testimonial-carousel";
import IntervalFilmsLogo from "../../assets/images/hackathon-as-a-service/interval-films-logo.png";

const testimonials = [
  {
    company_logo: IntervalFilmsLogo,
    company: "Interval Films",
    name: "Ollie Larkin",
    role: "Founder",
    testimonial:
      "They have not only provided us with a highly aesthetic, very technical and above all functional solution, they had a huge amount of creative input into our web app, meaning it’s not just catching up to or being on par with industry, it’s become the very best online experience for interacting with time-lapse content, bar none."
  }
];

/**
 * Testimonials
 */
const Testimonials: React.FC = () => (
  <>
    <h2 className="off-screen">
      Testimonials
    </h2>

    <TestimonialCarousel
      content={testimonials}
      backgroundColor="altLightGrey"
    />
  </>
);

export default Testimonials;
