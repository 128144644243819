import * as React from "react";

import { MediumColumnWidthWrapper } from "../confines-wrappers";
import styled, { css } from "../../theme/styled";
import { HeadingUppercase } from "../heading";
import {
  fontSizeHelper,
  fontWeightHelper
} from "../../theme/theme-helper";

/**
 * How you benefit
 */
const HowYouBenefit: React.FC = () => (
  <HowYouBenefitStyled className="how-you-benefit">
    <MediumColumnWidthWrapper className="content-container">
      <div className="how-you-benefit-wrapper">
        <HeadingUppercase
          as="h2"
          color="brandColor"
        >
          How you benefit
        </HeadingUppercase>

        <p className="large-copy">
          A hackathon kickstarts innovation and discovery, improves internal
          workflow, and encourages collaboration and team bonding.
        </p>

        <div className="lists-container">
          <ul className="list">
            <li>
              <h3>
                Innovation
              </h3>

              <ul>
                <li>
                  Discover new products and services
                </li>

                <li>
                  Encourage internal creativity
                </li>

                <li>
                  Solve your problems
                </li>
              </ul>
            </li>

            <li>
              <h3>
                Team
              </h3>

              <ul>
                <li>
                  Collaboration and integration across your organisation
                </li>

                <li>
                  Open platform for all
                </li>

                <li>
                  Encourage employee bonding
                </li>
              </ul>
            </li>

            <li>
              <h3>
                PR
              </h3>

              <ul>
                <li>
                  Enhance your public perception
                </li>

                <li>
                  Huge marketing potential
                </li>

                <li>
                  Enable recruitment opportunities
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </MediumColumnWidthWrapper>
  </HowYouBenefitStyled>
);

export default HowYouBenefit;

const HowYouBenefitStyled = styled.div`
  ${({ theme }) => css`
    .how-you-benefit-wrapper {
      .large-copy {
        padding-top: 4%;
        font-weight: ${fontWeightHelper({
    theme,
    variant: "heavy"
  })};
        font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_1"
  })};
      }

      .lists-container {
        padding-top: 3.8rem;

        .list {
          display: flex;
          flex-direction: column;
          padding-top: 0;
          padding-left: 0;
          list-style: none;

          > li {
            padding-top: 2.8rem;

            &:first-of-type {
              padding-top: 0;
            }

            h3 {
              font-weight: ${fontWeightHelper({
    theme,
    variant: "heavy"
  })};
              font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_1"
  })};
            }

            ul {
              padding-top: 1.2em;
              padding-left: 2.8rem;
              list-style-type: disc;
            }
          }
        }
      }
    }

    @media (min-width: 375px) {
      .how-you-benefit-wrapper {
        .large-copy {
          font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_2"
  })};
        }
      }
    }

    @media (min-width: 480px) {
      .how-you-benefit-wrapper {
        .large-copy {
          font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_3"
  })};
        }
      }
    }

    @media (min-width: 568px) {
      .how-you-benefit-wrapper {
        .large-copy {
          font-size: ${fontSizeHelper({
    theme,
    variant: "title"
  })};
        }
      }
    }

    @media (min-width: 600px) {
      .how-you-benefit-wrapper {
        .large-copy {
          padding-top: 1.8rem;
          font-size: ${fontSizeHelper({
    theme,
    variant: "display_1"
  })};
        }

        .lists-container {
          padding-top: 4.4rem;

          .list {
            > li {
              h3 {
                font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_2"
  })};
              }
            }
          }
        }
      }
    }

    @media (min-width: 768px) {
      .how-you-benefit-wrapper {
        .large-copy {
          font-size: ${fontSizeHelper({
    theme,
    variant: "display_2"
  })};
        }

        .lists-container {
          padding-top: 7.2rem;

          .list {
            flex-direction: row;
            justify-content: space-between;

            > li {
              padding-top: 0;
              width: 33.33%;
              max-width: 30rem;

              h3 {
                font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_2"
  })};
              }

              ul {
                padding-right: 2rem;
              }
            }
          }
        }
      }
    }

    @media (min-width: 960px) {
      .how-you-benefit-wrapper {
        .large-copy {
          padding-right: 0;
        }

        .lists-container {
          .list {
            > li {
              h3 {
                font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_3"
  })};
              }

              ul {
                padding-right: 2rem;
              }
            }
          }
        }
      }
    }

    @media (min-width: 1024px) {
      .how-you-benefit-wrapper {
        padding-right: 0;
        max-width: 93rem;

        .large-copy {
          padding-right: 0;
        }

        .lists-container {
          padding-top: 7.2rem;

          .list {
            > li {
              h3 {
                font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_3"
  })};
              }
            }
          }
        }
      }
    }

    @media (min-width: 1280px) {
      .how-you-benefit-wrapper {
        padding-right: 0;
        max-width: 106rem;

        .large-copy {
          font-size: ${fontSizeHelper({
    theme,
    variant: "display_3"
  })};
        }

        .lists-container {
          padding-top: 8.8rem;

          .list {
            > li {
              h3 {
                font-size: ${fontSizeHelper({
    theme,
    variant: "title"
  })};
              }
            }
          }
        }
      }
    }
  `}
`;
