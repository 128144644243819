import * as React from "react";
import { ParallaxBanner } from "react-scroll-parallax";

import styled, { css } from "../../theme/styled";
import GakuLogoImg from "../../assets/images/hackathon-as-a-service/gaku-logo.png";
import GakuLauraImg from "../../assets/images/hackathon-as-a-service/gaku-laura-palates.jpg";
import IntervalFilmsLogoImg from "../../assets/images/hackathon-as-a-service/interval-films-logo.png";
import IntervalFilmsImg from "../../assets/images/hackathon-as-a-service/interval-films.jpg";
import FoundLogoImg from "../../assets/images/hackathon-as-a-service/found-logo.png";
import FoundImg from "../../assets/images/hackathon-as-a-service/found.jpg";
import {
  colourHelper,
  fontSizeHelper
} from "../../theme/theme-helper";

/**
 * Examples
 */
const Examples: React.FC = () => (
  <ExamplesStyled>
    <h2 className="off-screen">
      Example projects
    </h2>

    <ul>
      <li className="gaku">
        <ParallaxBanner
          layers={[
            {
              amount: 0.1,
              children: (
                <img
                  className="full"
                  src={GakuLauraImg}
                  alt="Laura doing palates"
                />
              )
            }
          ]}
          style={{ height: "auto" }}
        >
          <div className="parallax-content-container">
            <div className="parallax-content-wrapper">
              <h3 className="off-screen">
                Gaku
              </h3>

              <div className="logo">
                <img
                  className="full"
                  src={GakuLogoImg}
                  alt="Gaku logo"
                />
              </div>

              <p>
                In just 5 days, we designed and built a simple, elegant video
                lesson app for pilates instructors.
              </p>
            </div>

            <span className="overlay" />
          </div>
        </ParallaxBanner>
      </li>

      <li className="interval-films">
        <ParallaxBanner
          layers={[
            {
              amount: 0.1,
              children: (
                <img
                  className="full"
                  src={IntervalFilmsImg}
                  alt="Smartphone showing the Interval Films web app"
                />
              )
            }
          ]}
          style={{ height: "auto" }}
        >
          <div className="parallax-content-container">
            <div className="parallax-content-wrapper">
              <h3 className="off-screen">
                Interval Films
              </h3>

              <div className="logo">
                <img
                  className="full"
                  src={IntervalFilmsLogoImg}
                  alt="Interval Films logo"
                />
              </div>

              <p>
                We designed and delivered a best-in-class timelapse software
                platform, in under a week.
              </p>
            </div>

            <span className="overlay" />
          </div>
        </ParallaxBanner>
      </li>

      <li className="found">
        <ParallaxBanner
          layers={[
            {
              amount: 0.1,
              children: (
                <img
                  className="full"
                  src={FoundImg}
                  alt="A cat with face detection"
                />
              )
            }
          ]}
          style={{ height: "auto" }}
        >
          <div className="parallax-content-container">
            <div className="parallax-content-wrapper">
              <h3 className="off-screen">
                Found
              </h3>

              <div className="logo">
                <img
                  className="full"
                  src={FoundLogoImg}
                  alt="Found logo"
                />
              </div>

              <p>
                In 6 days, we developed a machine learning model and app to
                reunite owners with their lost pets.
              </p>
            </div>

            <span className="overlay" />
          </div>
        </ParallaxBanner>
      </li>
    </ul>
  </ExamplesStyled>
);

const ExamplesStyled = styled.div`
  ${({ theme }) => css`
    ul {
      padding: 0;
      list-style: none;

      li {
        padding: 0;

        .parallax-banner {
          .parallax-content-container {
            position: relative;
            z-index: 2;
            padding-top: 115%;

            .parallax-content-wrapper {
              position: absolute;
              bottom: 8%;
              left: 0;
              z-index: 2;
              padding-left: 8%;
              padding-right: 8%;
              width: 100%;

              .logo {
                img {
                  margin: 0 auto;
                  width: 38%;
                  max-width: 18rem;
                }
              }

              p {
                margin: 0 auto;
                padding-top: 1em;
                max-width: 48rem;
                text-align: center;
                color: ${colourHelper({
    theme,
    variant: "white"
  })};
              }
            }

            .overlay {
              position: absolute;
              z-index: 1;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0) 1%,
                rgba(0, 0, 0, 0) 18%,
                rgba(0, 0, 0, 1) 100%
              );
            }
          }
        }
      }
    }

    @media (min-width: 600px) {
      ul {
        display: flex;

        li {
          width: 50%;

          &:last-child {
            display: none;
          }

          .parallax-banner {
            .parallax-content-container {
              .parallax-content-wrapper {
                .logo {
                  img {
                    width: 74%;
                  }
                }
              }
            }
          }
        }
      }
    }

    @media (min-width: 768px) {
      ul {
        li {
          width: 33.33%;

          &:last-child {
            display: block;
          }

          .parallax-banner {
            .parallax-content-container {
              .parallax-content-wrapper {
                .logo {
                  img {
                    width: 58%;
                  }
                }
              }
            }
          }
        }
      }
    }

    @media (min-width: 960px) {
      ul {
        li {
          .parallax-banner {
            .parallax-content-container {
              .parallax-content-wrapper {
                .logo {
                  img {
                    width: 44%;
                  }
                }
              }
            }
          }
        }
      }
    }

    @media (min-width: 1280px) {
      ul {
        li {
          .parallax-banner {
            .parallax-content-container {
              .parallax-content-wrapper {
                p {
                  font-size: ${fontSizeHelper({
    theme,
    variant: "body_2"
  })};
                }
              }
            }
          }
        }
      }
    }

    @media (min-width: 1380px) {
      ul {
        li {
          .parallax-banner {
            .parallax-content-container {
              .parallax-content-wrapper {
                p {
                  font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_1"
  })};
                }
              }
            }
          }
        }
      }
    }

    @media (min-width: 1920px) {
      ul {
        li {
          .parallax-banner {
            .parallax-content-container {
              .parallax-content-wrapper {
                p {
                  font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_2"
  })};
                }
              }
            }
          }
        }
      }
    }
  `}
`;

export default Examples;
