import * as React from "react";
import Parser from "html-react-parser";

import TextPanel from "../text-panel";

/**
 * Why Gravitywell?
 */
const WhyGravitywell: React.FC = () => (
  <div className="why-gravitywell">
    <TextPanel
      label="Why Gravitywell?"
      labelAs="h2"
      text={(
        <>
          {Parser(
            "<p>As award-winning creative thinkers, we have a wealth of experience guiding clients through every stage of their hackathon. Regardless of the challenge, our diverse team has the skill-set to take your vision to the next level.</p>"
          )}
        </>
      )}
      backgroundColor="white"
    />
  </div>
);

export default WhyGravitywell;
