import { graphql } from "gatsby";
import * as React from "react";
import styled from "styled-components";

import { IArticleData } from "../components/articles-grid/articles-grid";
import { FullWidthColumnWrapper } from "../components/confines-wrappers";
import Footer from "../components/footer";
import GetInTouch, { IGetInTouchLinkProps } from "../components/get-in-touch";
import Header from "../components/header";
import HelmetWrapper from "../components/helmet-wrapper";
import { breadCrumbSchema } from "../components/html-schema-snippets";
import Insights from "../components/insights";
import Layout from "../components/layout";
import PageTop from "../components/page-top";
import getProfile from "../templates/utils/getProfile";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "../theme/theme-helper";
import {
  AppsterAward,
  MediaInnovationAward
} from "../components/awards";
// import DynamicVideo from "../components/dynamic-video/dynamic-video";
// import { IconButton } from "../components/button/icon-button";
import {
  HowYouBenefit,
  How,
  WhyGravitywell,
  Testimonials,
  Examples
} from "../components/hackathon-as-a-services";
// import { HeadingUppercase } from "../components/heading";
import Link from "../components/link";
import CtaButton from "../components/cta/cta-button";
import HeroVideo from "../components/hero-video";
import VideoFallbackImage from "../assets/images/hackathon-as-a-service/hackathon-as-a-service-fallback.jpg";
import { IInsightsArticleData } from "./insights";

interface IProps {
  data: IApplicationDevelopmentProps;
}

export interface IApplicationDevelopmentProps {
  allNodeLabsArticle: {
    edges: IInsightsProps[];
  };
}

interface IInsightsProps {
  node: IInsightsArticleData;
}

const PageTopCustom = styled.div`
  /* .top-content {
    display: flex;
    flex-direction: column;
    padding-top: 10%;
    justify-content: center;
    padding-bottom: 10%;

    .top-main {
      .heading,
      .sub-heading {
        color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
      }

      .sub-heading {
        padding-top: 4%;
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
        font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
        line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
      }

      .video-wrapper {
        padding-top: 8%;
      }

      .play-button-container {
        padding-top: 8%;

        .play-button {
          margin: 0 auto;
        }
      }
    }

    .awards-container {
      padding-top: 12%;

      .awards-wrapper {
        display: flex;
        justify-content: center;

        > * {
          max-width: 20rem;

          &:nth-child(odd) {
            margin-right: 6%;
          }

          &:nth-child(even) {
            margin-left: 6%;
          }
        }
      }
    }
  }

  @media (min-width: 375px) {
    .top-content {
      .top-main {
        .sub-heading {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
        }
      }
    }
  }

  @media (min-width: 480px) {
    .top-content {
      .top-main {
        .sub-heading {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_1"
    })};
        }
      }
    }
  }

  @media (min-width: 568px) {
    .top-content {
      .top-main {
        .sub-heading {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_3"
    })};
        }
      }
    }
  }

  @media (min-width: 600px) {
    .top-content {
      .top-main {
        .sub-heading {
          padding-top: 2.2rem;
        }

        .video-wrapper {
          padding-top: 3.2rem;
        }

        .play-button-container {
          padding-top: 4.2rem;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .top-content {
      padding-top: 8.8rem;
      padding-bottom: 8.8rem;

      .top-main {
        .sub-heading {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_6"
    })};
        }
      }

      .awards-container {
        padding-top: 6.4rem;
      }
    }
  }

  @media (min-width: 960px) {
    .top-content {
      padding-top: 8.8rem;
      padding-bottom: 8.8rem;

      .top-main {
        position: relative;
        padding-left: 56%;
        min-height: 25.4rem;

        .heading {
          padding-top: 2.2rem;
        }

        .sub-heading {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_1"
    })};
        }

        .video-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          width: 50%;
          padding-top: 0;
        }

        .play-button-container {
          .play-button {
            margin: 0;
          }
        }
      }

      .awards-container {
        .awards-wrapper {
          width: 50%;

          > * {
            width: 36%;
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .top-content {
      .top-main {
        min-height: 27.2rem;

        .heading {
          padding-top: 2.8rem;
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .top-content {
      .top-main {
        min-height: 34.4rem;

        .heading {
          padding-top: 5.6rem;
        }

        .sub-heading {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_3"
    })};
        }
      }
    }
  }

  @media (min-width: 1380px) {
    .top-content {
      .top-main {
        min-height: 37.2rem;

        .heading {
          padding-top: 6.8rem;
        }

        .sub-heading {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_4"
    })};
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .top-content {
      .top-main {
        min-height: 39rem;

        .heading {
          padding-top: 7.4rem;
        }

        .sub-heading {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_4"
    })};
        }
      }
    }
  } */
  .top-content-container {
    .top-content-wrapper {
      position: absolute;
      left: 0;
      top: 0;

      .top-content {
        z-index: 3;
        text-align: center;

        .heading {
          transition: 0.2s ease-in-out;
          display: block;
          margin: 0 auto;
          width: 26rem;
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
        }

        .cta-button {
          padding-top: 6%;
        }
      }

      .appster-award,
      .media-innovation-award {
        position: absolute;
        z-index: 3;
        bottom: 2rem;
        max-width: 25.8rem;
        width: 14%;
        opacity: 0.75;
      }

      .appster-award {
        left: 2rem;
      }

      .media-innovation-award {
        right: 2rem;
      }
    }
  }

  @media (min-width: 375px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 32rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
          }

          .cta-button {
            padding-top: 6%;
          }
        }

        .appster-award,
        .media-innovation-award {
          width: 16%;
        }
      }
    }
  }

  @media (min-width: 480px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 39rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_1"
    })};
          }
        }

        .appster-award,
        .media-innovation-award {
          width: 9.6rem;
        }
      }
    }
  }

  @media (min-width: 568px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 53rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_3"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 600px) {
    .top-content-container {
      .top-content-wrapper {
        .appster-award,
        .media-innovation-award {
          width: 20%;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 60rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_4"
    })};
          }
        }

        .appster-award,
        .media-innovation-award {
          bottom: 5%;
          width: 15.4rem;
        }
      }
    }
  }

  @media (min-width: 960px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 66rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_5"
    })};
          }

          .cta-button {
            padding-top: 5.2rem;
          }
        }
      }
    }
  }

  @media (min-width: 960px) {
    .top-content-container {
      .top-content-wrapper {
        .appster-award,
        .media-innovation-award {
          width: 16%;
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 74rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_6"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 80rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_7"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1920px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 96rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_9"
    })};
          }
        }
      }
    }
  }
`;

const HackathonAsAServicePage = ({ data }: IProps) => {
  const canonicalPath = "/hackathon-as-a-service";

  const breadcrumbsSchemaData = breadCrumbSchema([
    {
      name: "Hackathon as a Service",
      path: canonicalPath
    }
  ]);

  const schemaJson = [ breadcrumbsSchemaData ];

  // const [isVideoPlaying, setIsVideoPlaying] = React.useState<boolean>(false);

  return (
    <Layout>
      <>
        <HelmetWrapper
          title="Hackathon as a Service"
          description="We help you innovate with a hackathon"
          canonicalPath={canonicalPath}
        >
          <script type="application/ld+json">
            {JSON.stringify(schemaJson)}
          </script>
        </HelmetWrapper>

        {/* <header role="banner">
          <Header initialLogo={true} initialTheme="dark" />

          <PageTopCustom id="hero">
            <PageTop
              style={{ paddingTop: 0, paddingBottom: 0 }}
              gradient="pinkPurple"
              height="auto"
            >
              <FullWidthColumnWrapper className="top-content">
                <div className="top-main">
                  <HeadingUppercase className="heading" as="p" color="white">
                    Hackathon as a Service
                  </HeadingUppercase>
                  <p className="sub-heading">
                    We help you innovate with a hackathon
                  </p>

                  <DynamicVideo
                    className="video-wrapper"
                    videoUrl="https://vimeo.com/289723432"
                    reactPlayer={{ playing: isVideoPlaying }}
                  />

                  <div className="play-button-container">
                    <IconButton
                      className="play-button"
                      ctaType="primary"
                      icon="play"
                      text="Watch the video"
                      onClick={() => setIsVideoPlaying(!isVideoPlaying)}
                    />
                  </div>
                </div>

                <div className="awards-container">
                  <div className="awards-wrapper">
                    <AppsterAward className="appster-award" />
                    <MediaInnovationAward className="media-innovation-award" />
                  </div>
                </div>
              </FullWidthColumnWrapper>
            </PageTop>
          </PageTopCustom>
        </header> */}

        <header role="banner">
          <Header
            initialLogo
            initialTheme="dark"
          />

          <PageTopCustom id="hero">
            <PageTop
              style={{
                paddingTop: 0,
                paddingBottom: 0
              }}
              height="large"
            >
              <HeroVideo
                src="https://cdn.gravitywell.co.uk/videos/hackathon-as-a-service/hackathon-as-a-service.m3u8"
                fallbackImage={VideoFallbackImage}
              />

              <FullWidthColumnWrapper className="top-content">
                <p className="heading">
                  We help you innovate with a hackathon
                </p>

                <CtaButton
                  type="primary"
                  href="/contact"
                  text="Let's talk"
                />
              </FullWidthColumnWrapper>

              <AppsterAward className="appster-award" />

              <MediaInnovationAward className="media-innovation-award" />
            </PageTop>
          </PageTopCustom>
        </header>

        <main
          id="main-content"
          role="main"
        >
          <h1 className="off-screen">
            Hackathon as a Service
          </h1>

          <HowYouBenefit />

          <How />

          <WhyGravitywell />

          <Examples />

          <Testimonials />

          <aside>
            <section className="latest-container">
              <h2 className="off-screen">
                Latest posts
              </h2>

              <Insights
                title="Insights"
                gradient="orangePink"
                articles={data.allNodeLabsArticle.edges.map(post => {
                  const profile = getProfile(post.node.relationships.author);

                  const articleObj: IArticleData = {
                    created: post.node.created,
                    path: post.node.path.alias,
                    title: post.node.title,
                    author: profile.field_full_name,
                    cover: post.node.relationships.field_cover_image
                  };

                  return articleObj;
                })}
              />
            </section>
          </aside>
        </main>

        <footer role="contentinfo">
          <GetInTouch
            title="Book your hackathon"
            slogan="Ready to collaborate and innovate?"
            links={[
              {
                href: "/projects",
                text: "More of our work"
              },
              {
                href: "/about-us",
                text: "Our process"
              },
              {
                href: "/about-us#meet-the-team",
                text: "Meet the team"
              }
            ]}
          />

          <Footer />
        </footer>
      </>
    </Layout>
  );
};

export default HackathonAsAServicePage;

export const query = graphql`
  query hackathonAsAServicePageQuery {
    allNodeLabsArticle(
      filter: { drupal_internal__nid: { in: [177, 237, 235] } }
    ) {
      edges {
        node {
          ...getLabsData
        }
      }
    }
  }
`;
